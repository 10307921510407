footer {
    padding-top: 40px;
    padding-bottom: 40px;
    background-color: #333333;
}

.footer-wrapper {
    width: 80%;
    border-bottom: 2px solid #7d7d7d;
    margin: 0 auto;
}

.footer-content {
    width: 80%;
    margin: 30px auto;
    overflow: hidden;
    position: relative;
}

.footer-content-column {
    width: 20%;
    float: left;
}

.footer-content-primary-link {
    margin-bottom: 45px;
}

.footer-content-primary-link a {
    text-decoration: none;
    position: relative;
    color: #fff;
    font-size: 1.3em;
}

.footer-content-primary-link a:before{
    content: "";
    position: absolute;
    width: 70px;
    height: 1px;
    bottom: -15px;
    left: 25%;
    margin-left: -20px;
    border-bottom: 1px solid #7d7d7d;
}

.footer-content-link {
    margin-bottom: 20px;
    font-size: 1.1em;
}

.footer-content-link a {
    color: #7d7d7d;
    text-decoration: none;
}

.footer-content-primary-link a:hover,
.footer-content-link a:hover {
    color: #f9ea01;
}

.footer-content-primary-link a:hover:before {
    border-bottom: 1px solid #f9ea01;
}

.footer-social-area {
    text-align: center;
    padding-top: 30px;
    position: relative;
    width: 80%;
    margin: 20px auto;
}

.footer-logo {
    text-align: center;
    font-weight: 700;
    font-size: 2.2em;
    color: #fff;
    text-decoration: underline solid #f9ea01;
}

.footer-logo img {
    margin-top: -10px;
    height: 64px;
    width: auto;
}

.footer-social-icons {
    position: absolute;
    right: 20px;
    top: 41px;
    overflow: hidden;
    width: 200px;
}

.footer-social-icons > .social-icon {
    display: inline-block;
    margin-left: 8px;
}

.footer-disclaimer-links {
    margin: 20px auto;
    overflow: hidden;
    padding-bottom: 50px;
}

.footer-disclaimer-links-column {
    text-align: center;
    float: left;
    width: 33%;
}

.footer-disclaimer-links-column:first-child {
    text-align: left;
}

.footer-disclaimer-links-column:last-child {
    text-align: right;
}

.footer-disclaimer-link {
    color: #7d7d7d;
}

a.footer-disclaimer-link {
    margin-right: 30px;
    text-decoration: none;
}

a.footer-disclaimer-link:hover {
    color: #f9ea01;
    text-decoration: underline solid #f9ea01;
}

.footer-cta .action-button {
    margin-top: calc(50% - 21px);
    color: #fff;
    border: 2px solid #fff;
    text-align: center;
}

.footer-cta .action-button:hover {
    border: 2px solid #F8EA09;
    background-color: #333333;
    color: #F8EA09;
}


@media (max-width: 1200px){
    .footer-content-column {
        width: 100%;
        float: none;
        margin-bottom: 20px;
    }

    .footer-content-column:last-child {
        margin-bottom: 0;
    }

    .footer-social-icons {
        position: static;
        right: 0;
        width: 100%;
        text-align: center;
    }
}