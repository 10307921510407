.faq-primary-section {
    min-height: 600px;
    background: url('../images/faq.jpg') no-repeat;
}

.faq-primary-section .primary-section-content {
    top: 450px;
    /*bottom: 100px;*/
    text-align: left;
}

.transparent-heading {
    text-align: left;
    color: rgba(255, 255, 255, .6);
    display: none;
    margin-left: 400px;
    font-weight: 700;
    font-size: 3em;
}

@media (max-width: 1080px){
    .transparent-heading {
        margin-left: 50px;
    }
}

.transparent-heading:before {
    display: none !important;
}

.faq h4 {
    text-align: left;
    font-size: 2em;
}

.faq h5 {
    color: #030303;
    /*font-weight: 700;*/
    margin-bottom: 0;
    font-size: 1.1em;
}