.about-primary-section {
    min-height: 800px;
    background: url("../images/about_us/image_baner.png") no-repeat;
}

.who-we-are-image {
    margin: 20px auto 80px;
}

@media (max-width: 1480px) {
    .who-we-are-image img {
        width: 600px;
        height: auto;
    }
}

@media (max-width: 1280px) {
    .who-we-are-image img {
        width: 400px;
        height: auto;
    }
}

@media (max-width: 780px) {
    .who-we-are-image img {
        width: 300px;
        height: auto;
    }
}

@media (max-width: 560px) {
    .who-we-are-image img {
        width: 200px;
        height: auto;
    }
}