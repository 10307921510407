
.eyeon-primary-section {
    min-height: 850px;
   /* background: url("../images/eye_one/BLI_eyeon_v2.png") no-repeat;*/
    /*filter: brightness(60%);*/
}

.eyeon-primary-section .primary-section-background {
    background: url("../images/eye_one/BLI_eyeon_v2.png") no-repeat;
    filter: brightness(70%);
}

.eyeon-primary-section .primary-section-scroll-icon {
    bottom: 150px;
}

.eyeon-primary-section {
    position: relative;
    /*min-height: 1350px;*/
}

.eyeon-section {
    background: #fff !important;
}

.eyeon-section .fixed-section {
    position: relative;
    background: none;
    margin-left: auto;
    margin-right: auto;
    top: -120px;
    width: 80%;
    bottom: 0;
    right: 0;
    left: 0;
    padding-bottom: 0;
}

.eyeon-section .fixed-section .section-content {
    padding-top: 20px;
    width: 100%;
    min-height: 0 !important;
    box-shadow: none;
    padding-bottom: 0;
}

.eyeon-section .section-text {
    padding: 0 120px;
}

.lined-list {
    display: block;
    margin: 20px auto;
}

.lined-list li {
    display: inline-block;
    margin-right: 10px;
    font-size: 1.2em;
}

.lined-list li.active {
    color:  #F8EA09;
}

.lined-list li:hover {
    color:  #F8EA09;
    cursor: pointer;
}

.list-divider {
    margin-left: 10px;
    display: inline-block;
}

.eyeon-tab-content {
    display: none;
}

.eyeon-tab-content.active {
    display: block;
}

.eye-one-section-buttons-container {
    overflow: hidden;
    width: 80%;
    margin: 0 auto 40px;
    position: relative;
    bottom: 40px;
}

.eye-one-section-buttons-container .section-action-button-container {
    float: left;
    width: 33%;
}

.eye-one-section-buttons-container .section-action-button-container .action-button {
    width: 80%;
    margin: 0 auto;
}

.eyeone-arrow-switcher-container {
    /*overflow: hidden;*/
}

.eyeone-arrow-switcher {
    overflow: hidden;
}

.eye-one-arrow-control {
    float: right;
    margin-left: 30px;
    font-size: 1.3em;
}

.eye-one-arrow-control.disabled {
    opacity: .4;
}

