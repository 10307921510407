#main-container {
    -webkit-transition: 0.5s;
    -moz-transition: 0.5s;
    -ms-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
}

/* styles for main landing content */
.primary-section {
    min-height: 700px;
    text-align: center;
    position: relative;
}

.primary-section:after {
    content: "";
    display: table;
    clear: both;
}

.primary-section-background {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.primary-section-content {
    position: absolute;
    top: 30%;
    display: block;
    width: 100%;
    color: #fff;
}

@media (max-width: 480px) {
    .primary-section-content {
        top: 100px;
    }
}

.primary-section-video-container {
    position: absolute;
    z-index: -1;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    overflow: hidden;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-image: none;
    filter: url("data:image/svg+xml;utf9,<svg%20version='1.1'%20xmlns='http://www.w3.org/2000/svg'><filter%20id='blur'><feGaussianBlur%20stdDeviation='10'%20/></filter></svg>#blur");
    -webkit-filter: brightness(50%);
    -o-filter: brightness(50%);
    filter: brightness(50%);
}

.primary-section-scroll-icon {
    position: absolute;
    bottom: 90px;
    left: 50%;
    color: #fff;
    margin-left: -27px;
}

.primary-section-scroll-icon div {
    margin-top: 12px;
}

@media (max-width: 980px) {
    .primary-section-scroll-icon {
        bottom: 20px;
    }
}

.primary-section h1 {
    /*font-family: 'Oswald', sans-serif;*/
    font-size: 2.7em;
    display: inline-block;
}

.section-content h2,
.primary-section h1 {
    font-weight: 700;
    text-transform: uppercase;
    position: relative;
    z-index: 3;
    display: inline-block;
}

.primary-section h1:before {
    /*display: block;
    content:'';
    position:absolute;
    width: 100%;
    bottom: 3px;
    border-bottom: 15px solid #FEDD00;
    z-index: -1;*/
}

.section-content h2:before {
    display: block;
    content:'';
    position:absolute;
    width: calc(100% + 40px);
    margin-left: -20px;
    bottom: 3px;
    border-bottom: 15px solid #FEDD00;
    z-index: -1;
}

.primary-section-text {
    font-size: 1.4em;
    width: 60%;
    margin: 30px auto;
}

.section-content {
    text-align: center;
    width: 60%;
    margin: 0 auto;
    padding: 50px 20px 120px;
}

.landing-section:nth-child(odd) {
    background-color: #F7F8F9;
}

.section-content h2 {
    font-size: 2.3em;
    margin-bottom: 35px;
    display: inline-block;
    position: relative;
    font-weight: 700;
}

.section-content h4 {
    font-size: 1.8em;
    font-weight: 400;
    margin-bottom: 35px;
}

.section-text {
    margin: 0 auto 40px;
    font-size: 1.4em;
    color: #757575;
}

.section-content.row-content {
    width: 70%;
    margin: 0 auto;
}

.section-row-container {
    overflow: hidden;
}

.section-column {
    float: left;
    width: 33%;
}

.section-column-content .description-text {
    height: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-wrap: none;
    padding: 20px;
}

.section-column:first-child .section-column-content {
    padding-right: 50px;
}

.section-column:last-child .section-column-content {
    padding-left: 50px;
}

@media screen and (max-width: 1348px){
    .section-column {
        float: none;
        width: 100%;
        margin-bottom: 50px;
    }

    .section-column:last-child {
        margin-bottom: 0;
    }

    .section-column-content .description-text {
        height: auto;
    }
}

.underlined-title {
    position: relative;
}

.underlined-title:before {
    content: "";
    position: absolute;
    width: 40px;
    height: 1px;
    bottom: -20px;
    left: 50%;
    margin-left: -20px;
    border-bottom: 1px solid #030303;
}

.action-button {
    display: inline-block;
    padding: 5px 30px;
    font-size: 1.4em;
    text-transform: uppercase;
    border: 2px solid #030303;
    border-radius: 3px;
    text-decoration: none;
    color: #030303;
}

.action-button:hover {
    border-color: #F8EA09;
    background-color: #F8EA09;
    cursor: pointer;
    color: #030303;
    transition: all 250ms ease-in;
}

.blog-preview {
    padding-top: 100px;
}

.blog-preview, .solutions {
    padding-bottom: 120px;
}

.blog-preview h2 {
    margin-bottom: 15px;
}

.heading-icon {
    margin: 10px auto 50px;
    display: block;
} 

.blog-preview .heading-icon {
    margin-bottom: 60px;
}

.blog-preview .blog-thumb img {
    text-align: center;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
}

.secondary-video {
    position: relative;
    min-height: 1000px;
}

@media (min-width: 1800px) {
    .secondary-video {
        min-height: 1000px;
    }
}

@media (max-width: 1799px) {
    .secondary-video {
        min-height: 800px;
    }
}

@media (max-width: 1600px) {
    .secondary-video {
        min-height: 750px;
    }
}

@media (max-width: 1400px) {
    .secondary-video {
        min-height: 700px;
    }
}

@media (max-width: 1300px) {
    .secondary-video {
        min-height: 650px;
    }
}

@media (max-width: 1200px) {
    .secondary-video {
        min-height: 600px;
    }
}

@media (max-width: 1000px) {
    .secondary-video {
        min-height: 550px;
    }
}

@media (max-width: 900px) {
    .secondary-video {
        min-height: 500px;
    }
}

@media (max-width: 800px) {
    .secondary-video {
        min-height: 500px;
    }
}

@media (max-width: 700px) {
    .secondary-video {
        min-height: 450px;
    }
}

@media (max-width: 599px) {
    .secondary-video {
        min-height: 300px;
    }
}

.secondary-video-container {
    position: absolute;
    z-index: 2;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    overflow: hidden;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 20%;
    background-image: none;
}

#secondary-video {
    margin: auto;
    position: absolute;
    z-index: -1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -25%);
    visibility: visible;
    opacity: 1;
    height: auto;
    width: 100%;
    bottom: 0;
}

.video-control-button {
    position: absolute;
    z-index: 5;
    top: calc(50% - 24px);
    left: 50%;
    height: 48px;
    width: 48px;
}

#secondary-video-control-pause {
    display: none;
}

.video-play-button:hover {
    cursor: pointer;
}

.fixed-section {
    position: absolute;
    z-index: 20;
    top: 450px;
    padding: 20px 30px 60px;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
}


.fixed-section .section-content {
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    background-color: #fff;
    min-height: 700px;
}

.section-content.left-aligned .section-text, h4.left-aligned {
    text-align: left;
}
