.demo-section .section-content h2 {
    text-transform: none;
}

.demo-section .section-content h2:before {
    display: none;
}

.input-group {
    margin-bottom: 40px;
    width: 100%;
    max-width: 600px;
}

.input-group input[type="text"] {
    height: 48px;
    line-height: 48px;
    font-size: 24px;
    padding: 0 20px;
    color: #7d7d7d;
    display: block;
    width: 100%;
    margin: auto;
    border: 1px solid #030303;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}

.input-group input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #7d7d7d;
    opacity: .7;
}
.input-group input::-moz-placeholder { /* Firefox 19+ */
    color: #7d7d7d;
    opacity: .7;
}
.input-group input:-ms-input-placeholder { /* IE 10+ */
    color: #7d7d7d;
    opacity: .7;
}
.input-group input:-moz-placeholder { /* Firefox 18- */
    color: #7d7d7d;
    opacity: .7;
}

.input-group-title {
    text-align: left;
    color: #030303;
}

.checkbox-group {
    overflow: hidden;
    max-width: 600px;
    margin: 30px auto;
    text-align: left;
}

.checkbox-group .checkbox-container {
    float: left;
    margin-bottom: 30px;
    width: 50%;
    color: #030303;
}

.checkbox-container input[type="checkbox"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: #fafafa;
    border: 1px solid #030303;
    box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px -15px 10px -12px rgba(0,0,0,0.05);
    padding: 9px;
    border-radius: 3px;
    display: inline-block;
    position: relative;
    top: 6px;
    margin-right: 10px;
}

.checkbox-container input[type="checkbox"]:active,
.checkbox-container input[type="checkbox"]:checked {
    background-color: #030303;
    outline: none;
    -webkit-transition: all ease-out 300ms;
    -moz-transition: all ease-out 300ms;
    -ms-transition: all ease-out 300ms;
    -o-transition: all ease-out 300ms;
    transition: all ease-out 300ms;
}

.checkbox-container input[type="checkbox"]:checked:after {
    content: '\2714';
    font-size: 24px;
    position: absolute;
    top: -5px;
    left: 0;
    color: #F8EA09;
}