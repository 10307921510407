.sidenav-container {
    width: 260px;
    height: 100%;
    position: fixed;
    top: 0;
    right: -260px;
    background-color: #fff;
    padding-top: 70px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    z-index: 99999 !important;
    overflow-x: hidden;
    transition: 0.5s;
}

.sidenav-container .closebtn {
    position: absolute;
    top: 20px;
    right: 30px;
    text-decoration: none;
    color: #030303;
}

.sidenav-container .closebtn .material-icons {
    font-size: 34px;
}

.sidenav-menu {
    list-style: none;
}

.sidenav-menu li {
    padding: 20px;
}

.sidenav-menu li a {
    font-size: 1.3em;
    text-decoration: none;
    color: #030303;
    font-weight: 700;
}

.sidenav-menu li:hover a:before {
    display: block;
    content:'';
    position:absolute;
    width:100%;
    bottom:0;
    left:0;
    background:#FEDD00;
    z-index: 900;
}

.sidenav-menu li a:hover:before {
    display: block;
    content:'';
    position:absolute;
    width:100%;
    bottom: 3px;
    left:0;
    border-bottom: 8px solid #FEDD00;
    z-index: -1;
}