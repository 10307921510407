.solution-primary-section {
    min-height: 825px;
}

.solution-primary-section .primary-section-background {
    background: url("../images/solution/BLI_solutions_v2.png") no-repeat;
    filter: brightness(70%);
}

.solution-primary-section .primary-section-scroll-icon {
    bottom: 150px;
}

.solution-section {
    position: relative;
    /*min-height: 1350px;*/
}

.solution-section .fixed-section {
    position: relative;
    background: none;
    margin-left: auto;
    margin-right: auto;
    top: -120px;
    width: 80%;
    bottom: 0;
    right: 0;
    left: 0;
    padding-bottom: 0;
    /*padding-top: 150px;*/
}

.solution-section .fixed-section .section-content {
    padding-top: 20px;
    width: 100%;
    box-shadow: none;
}

.solution-section .fixed-section .section-text.description-text {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.section-action-button-container-left {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
}

.section-action-button-container-left .action-button {
    float: right;
    padding: 5px 25px;
    font-size: 1.3em;
    text-transform: uppercase;
    border: 2px solid #030303;
    border-radius: 3px;
    text-decoration: none;
    color: #030303;
}

.section-action-button-container-left .action-button:hover {
    border-color: #F8EA09;
    background-color: #F8EA09;
    cursor: pointer;
    color: #030303;
}

.solutions-switch-content-section {
    position: relative;
    top: -270px;
}

.section-content h2.transparent-heading {
    text-align: left;
    color: rgba(255, 255, 255, .6);
    display: none;
    margin-left: 120px;
    font-weight: 700;
    font-size: 3em;
    margin-bottom: 50px;
}

.transparent-heading:before,
.section-content h2.transparent-heading:before{
    display: none !important;
}

.section-content h2.transparent-heading.active {
    display: block !important;
}

.solutions-submenu {
    text-align: left;
    color: #fff;
    margin-left: 80px;
}

.solutions-submenu li {
    display: inline-block;
    margin-right: 10px;
    font-size: 1.2em;
}

.solutions-submenu li.active {
    color:  #F8EA09;
}

.solutions-submenu li:hover {
    color:  #F8EA09;
    cursor: pointer;
}

.list-divider {
    margin-left: 10px;
    display: inline-block;
    color: #fff;
}

.solutions-subsections {
    /*overflow: hidden;*/
    padding-left: 100px;
    padding-right: 100px;
}

.solutions-subsection {
    float: left;
    width: 25%;
}

.section-action-button-container {
    overflow: hidden;
}

.solutions-subsection h4 {
    font-size: 1.3em;
    position: relative;
}

.solutions-subsection h4:before {
    content: "";
    position: absolute;
    width: 40px;
    height: 1px;
    bottom: -20px;
    left: 50%;
    margin-left: -20px;
    border-bottom: 1px solid #030303;
}

.solutions-subsection-content {
    width: 90%;
    margin: 0 auto;
    background-color: #fff;
    padding: 10px 20px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}

.solutions-subsection-text {
    min-height: 140px;
}

.solutions-subsection-action-button {
    float: right;
    font-size: 1.3em;
    padding: 5px 15px;
}

.solution-content-switcher {
    overflow: hidden;
    margin-top: 100px;
    margin-bottom: 80px;
}

.solution-content-title {
    float: left;
    width: 33%;
    text-align: center;
}

.solution-content-title .solution-switcher {
    font-size: 1.8em;
    text-transform: none;
}

.section-content h2.solution-switcher:before {
    display: none;
}

h2.solution-switcher:hover::before {
    cursor: pointer;
    display: block;
    content:'';
    position:absolute;
    width: calc(100% + 40px);
    margin-left: -20px;
    bottom: 3px;
    border-bottom: 15px solid #FEDD00;
    z-index: -1;
}

h2.solution-switcher.active:before {
    display: block;
    content:'';
    position:absolute;
    width: calc(100% + 40px);
    margin-left: -20px;
    bottom: 3px;
    border-bottom: 15px solid #FEDD00;
    z-index: -1;
}