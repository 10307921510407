.section-tabs {
    width: 500px;
    margin: 20px auto 50px;
    background-color: #f1f1f1;
    overflow: hidden;
    padding: 5px 5px;
}

.section-tab-control,
.solutions-section-tab-control {
    float: left;
    color: #9e9e9e;
    text-align: center;
    width: 25%;
    padding-top: 5px;
    padding-bottom: 5px;
}

.section-tab-control.active,
.solutions-section-tab-control.active {
    color: #030303;
    background-color: #fff;
}

.section-tab-control:hover,
.solutions-section-tab-control:hover {
    cursor: pointer;
    color: #030303;
    background-color: #fff;
}

.tab-content.active {
    display: block;
}

.tab-content {
    display: none;
}

.tab-content-bordered {
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    padding: 20px 30px;
}
