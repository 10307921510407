header {
    width: 100%;
    position: fixed;
    height: 69px;
    line-height: 68px;
    border-bottom: 1px solid rgba(255,255,255, .2);
    background: rgb(255,255,255);
    color: #030303;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    z-index: 9999 !important;
}

.header.not-transparent {
    background: rgb(255,255,255);
    color: #030303;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    z-index: 9999 !important;
}

header > div {
    float: left;
}

.header-logo, .header-logo-dark {
    padding-left: 50px;
    padding-right: 20px;
    text-decoration: underline solid #f9ea01;
    line-height: 68px;
}

.header .header-logo {
    display: none;
}

.header.not-transparent .header-logo-dark,
.header .header-logo-dark {
    display: block;
}

.header-logo img,
.header-logo-dark img {
    margin-top: 3px;
    height: 64px;
}

header .header-nav {
    width: 80%; /*temp */
}

.header-nav .nav {
    display: block;
    margin: 3px auto;
    text-align: center;
}

.header-nav .nav li {
    display: inline-block;
    padding-left: 20px;
    padding-right: 20px;
}

.header-nav .nav li a {
    font-size: 1.3em;
    text-decoration: none;
    color: #030303;
    font-weight: 700;
    position: relative;
}

.header-nav .nav li a:hover {
    cursor: pointer;
}

.header-nav .nav li:hover a:before {
    display: block;
    content:'';
    position:absolute;
    width:100%;
    bottom:0;
    left:0;
    background:#FEDD00;
    z-index:30;
}

.header .header-nav .nav li a:hover:before {
    display: block;
    content:'';
    position:absolute;
    width:100%;
    bottom: 3px;
    left:0;
    border-bottom: 8px solid #FEDD00;
    z-index: -1;
}

.header-login {
     font-size: 1.3em;
     float: right;
     padding-right: 80px;
}

.nav-menu-item {
    position: relative;
}

.header-nav-submenu {
    position: absolute;
    top: 50px;
    left: 0;
    background-color: #fff;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    width: 135px;
    z-index: 99999;
    display: none;
}

.header-nav-submenu.visible {
    display: block;
}

.header-nav-submenu ul {
    padding-left: 0;
    padding-right: 0;
    line-height: 45px;
}

.header-mobile-toggle {
    font-size: 1.3em;
    position: absolute;
    top: 10px;
    right: 30px;
    display: none;
}

.header-mobile-toggle .material-icons {
    font-size: 32px;
}

.header-mobile-toggle .material-icons:hover {
    cursor: pointer;
}

@media screen and (max-width: 932px) {
    .header-mobile-toggle {
        display: block;
    }

    .header-nav .nav {
        display: none;
    }
}